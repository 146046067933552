import { useContext } from "react";
import { MapContext } from "./MapContextContainer";
import { useTranslation } from "react-i18next";

function useSetPopupContent() {
    var mapContext = useContext(MapContext);
    const { i18n } = useTranslation();

    const setPopupContent = (feature: any): string => {
        const notificationType = feature.graphic.attributes.CUTTINGREALIZATIONPRACTICE || feature.graphic.attributes.HAKKUUNTOTEUTTAMISTAPA;
        const notificationState: string = (feature.graphic.attributes.DECLARATIONSTATE || feature.graphic.attributes.TILA) + "";

        const habitatCode = feature.graphic.attributes.HABITATCODE || feature.graphic.attributes.ERITYISENTARKEAELINYMP;
        const detailedHabitatCode = feature.graphic.attributes.DETAILEDHABITATCODE || feature.graphic.attributes.ERITYISENTARKEAELINYMPTARK;
        const otherHabitatCode = feature.graphic.attributes.OTHERHABITATCODE || feature.graphic.attributes.MUULUONTOKOHDE;

        let notificationText = "tuntematon";
        let stateText = "tuntematon";
        let habitatText = "";
        let detailedHabitatText = "";
        let otherHabitatText = "";

        const fullDate = new Intl.DateTimeFormat("fi", {
            dateStyle: "long",
            timeStyle: "short"
        });

        const fullDateString = fullDate.format(feature.graphic.attributes.DECLARATIONARRIVALDATE || feature.graphic.attributes.SAAPUMISPVM);

        if (notificationType) {
            notificationText = mapContext.getNotificationTypeText(notificationType);
        }

        if (notificationState) {
            stateText = getNotificationStateText(notificationState, i18n.language);
        }

        if (habitatCode) {
            habitatText = mapContext.getCodeText(habitatCode, "habitatCodes");
        }

        if (detailedHabitatCode) {
            detailedHabitatText = mapContext.getCodeText(detailedHabitatCode, "detailedHabitatCodes");
        }

        if (otherHabitatCode) {
            otherHabitatText = mapContext.getCodeText(otherHabitatCode, "otherHabitatCodes");
        }

        const standNumber = feature.graphic.attributes.STANDNUMBER || feature.graphic.attributes.KUVIONUMERO;
        const declarationNumber = feature.graphic.attributes.FORESTUSEDECLARATIONNUMBER || feature.graphic.attributes.MKINUMERO;
        const meanAge = feature.graphic.attributes.MEANAGE || feature.graphic.attributes.IKA || "n/a";
        const area = feature.graphic.attributes.AREA || feature.graphic.attributes.PINTA_ALA;

        if (i18n.language === "en") {
            return `
                <p>
                    <span><b>Declaration number</b>: ${declarationNumber}.</span><br/>
                    <span><b>Stand number</b>: ${standNumber}.</span><br/>
                    <span><b>Arrival date:</b> ${fullDateString}.</span>
                </p>
                <p>
                    <b>Cutting practice:</b> ${notificationText}. <b>Status</b>: ${stateText}
                </p>
                <p>
                    <span><b>Important habitat (if any)</b>: ${habitatText}</span><br/>
                    <span><b>Important habitat specification (if any)</b>: ${detailedHabitatText}</span><br/>
                    <span><b>Other habitat (if any)</b>: ${otherHabitatText}</span>
                </p>            
                <p>
                    <b>Mean age of trees:</b> ${meanAge}<br/>
                    <b>Area</b>: ${area}ha
                </p>
                <p>
                    ${feature.graphic.attributes.HAKKUUNTOTEUTTAMISTAPA ? "<span>BETA</span>" : ""}
                </p>
            `;
        }
        else {
            return `
                <p>
                    <span><b>MKI-numero</b>: ${declarationNumber}.</span><br/>
                    <span><b>Kuvionumero</b>: ${standNumber}.</span><br/>
                    <span><b>Saapumispäivä:</b> ${fullDateString}.</span>
                </p>
                <p>
                    <b>Toimenpide:</b> ${notificationText}. <b>Tila</b>: ${stateText}
                </p>
                <p>
                    <span><b>Tärkeä elinympäristö</b>: ${habitatText}</span><br/>
                    <span><b>Tärkeän elinympäristön tarkenne</b>: ${detailedHabitatText}</span><br/>
                    <span><b>Muu luontokohde</b>: ${otherHabitatText}</span>
                </p>            
                <p>
                    <b>Puuston ikä:</b> ${meanAge}<br/>
                    <b>Pinta-ala</b>: ${area}ha
                </p>
                <p>
                    ${feature.graphic.attributes.HAKKUUNTOTEUTTAMISTAPA ? "<span>BETA</span>" : ""}
                </p>
            `;
        }
    }

    return setPopupContent;
};

function getNotificationStateText(state: string, language: string) {
    switch (state) {
        case "10":
            return language === "en" ? "Automatically reviewed" : "Automaattisesti tarkastettu";
        case "20":
            return language === "en" ? "Checked" : "Asiatarkastettu";
        case "31":
            return language === "en" ? "Being processed" : "Käsittelyssä";
        case "32":
            return language === "en" ? "Unnecessary" : "Tarpeeton";
        case "33":
            return language === "en" ? "Transferred to another authority" : "Siirretty toiselle viranomaiselle";
        case "34":
            return language === "en" ? "Cut forbidden" : "Hakkuu kielletty";
        case "40":
            return language === "en" ? "Valid" : "Voimassa";
        case "41":
            return language === "en" ? "Valid with modifications" : "Voimassa muutettuna";
        case "50":
            return language === "en" ? "Expired" : "Vanhentunut";
        default:
            return language === "en" ? "unknown" : "tuntematon";
    }
};

export default useSetPopupContent;