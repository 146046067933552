export interface IErrors {
    notificationLayerLoadError: IError,
    codeSetsLoadingError: IError,
    naturalForestsLoadingError: IError,
    taxonAndCollectionMappingsDataLoadingError: IError,
    failedToNavigateToObservationClash: IError,
    specialFeatureCodeMappingsDataLoadingError: IError,
    loadingHabitatsForMapPageFailed: IError,
    loadingHabitatsGridFailed: IError,
    loadingObservationsGridFailed: IError,
    loadingObservationsForMapPageFailed: IError,
    layerLoadingFailed: IError
}

export interface IError {
    translationKey: string,
    level: "dismissable" | "fatal",
    replacementValues?: any[]
}

const errors: IErrors = {
    notificationLayerLoadError: {
        level: "dismissable",
        translationKey: "virheHaettaessaMKITietoja"
    },
    codeSetsLoadingError: {
        level: "dismissable",
        translationKey: "virheHaettaessaKoodistoja"
    },
    naturalForestsLoadingError: {
        level: "fatal",
        translationKey: "virheHaettaessaLuonnonmetsiä"
    },
    taxonAndCollectionMappingsDataLoadingError: {
        level: "dismissable",
        translationKey: "virheHaettaessaTaksoniJaKeräilytietoja"
    },
    specialFeatureCodeMappingsDataLoadingError: {
        level: "dismissable",
        translationKey: "virheHaettaessaElinympäristöjenKoodistoja"
    },
    failedToNavigateToObservationClash: {
        level: "dismissable",
        translationKey: "virheNavigoidessaLajihavaintoon"
    },
    loadingHabitatsForMapPageFailed: {
        level: "dismissable",
        translationKey: "virheHaettaessaElinympäristöjäKartalle"
    },
    loadingHabitatsGridFailed: {
        level: "fatal",
        translationKey: "virheHaettaessaElinympäristöGridiä"
    },
    loadingObservationsGridFailed: {
        level: "fatal",
        translationKey: "virheHaettaessaLajihavaintoGridiä"
    },
    loadingObservationsForMapPageFailed: {
        level: "fatal",
        translationKey: "virheHaettaessaLajihavaintojaKartalle"
    },
    layerLoadingFailed: {
        level: "dismissable",
        translationKey: "virheLadattaessaKarttatasoa"
    }
};

export default errors;
