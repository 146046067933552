import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AppSettingsContext } from '../../App/AppSettingsContextContainer';
import SignInSignOutButton from '../SignInSignOutButtons/SignInSignOutButton';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { Trans, useTranslation } from 'react-i18next';

interface ITopMenuContainerProps {
    backgroundColor: string
    borderColor: string
}

interface INavItemProps {
    isCurrent: boolean,
    currentBackgroundColor: string
}

const TopMenuContainer = styled.div<ITopMenuContainerProps>`
    height: 4em; 
    min-height: 4em;
    background-color: ${props => props.backgroundColor};
    border-bottom: 5px solid;
    border-color: ${props => props.borderColor};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TopMenuNav = styled.nav`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

const NavItem = styled.div<INavItemProps>`
    & a {
        color: black;
    }

    font-size: 1.5em;
    width: 7em;
    white-space: nowrap;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin: 0 2em 0 2em;
    padding: 0 3em 0 3em;

    & a:hover {
        text-decoration: ${props => props.isCurrent ? "none" : "underline"};
    }

    background-color: ${props => props.isCurrent ? props.currentBackgroundColor : ""};
`;

const AlertsNavItem = styled(NavItem)`
    width: 10em;
`;

const SignInSignOutButtonContainer = styled.div`
    margin-left: auto;
    margin-right: 1em;
    margin-top: 0.5em;
`;

const TwoRowContainerTop = styled.div`
    display: flex;
    padding-left: 0.5em;
    flex-direction: row;
    margin-bottom: -0.5em;
`;

const TwoRowContainerDown = styled.div`
    display: flex;
    padding-left: 1em;
    margin-top: -0.5em;
    flex-direction: row-reverse;
`;

const BetaText = styled.span`
	color: red;
    transform: rotate(270deg);
    padding-top: 0.5em;
`;

const TwoRowNavItemTextContainer = styled.div`
    justify-content: space-evenly;
    flex-direction: column;
    padding: 0;    
`;

const TwoRowNavItemContainer = styled.div`
    display: flex;
`;

const twoRowNavItemComponents = {
    TwoRowNavItemTextContainer: <TwoRowNavItemTextContainer />, TwoRowContainerTop: <TwoRowContainerTop />, TwoRowContainerDown: <TwoRowContainerDown />, span: <span />
};

export default function TopMenu() {
    const appSettingsContext = useContext(AppSettingsContext);
    const location = useLocation();
    const currentBackgroundColor = appSettingsContext.colors[5];
    const { t } = useTranslation("menus", { useSuspense: true });

    return (
        <TopMenuContainer backgroundColor={appSettingsContext.colors[3]} borderColor={appSettingsContext.colors[5]}>
            <TopMenuNav>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/"}><Link to={"/"}>{t("etusivu")}</Link></NavItem>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/luonnonmetsat"}><Link to={"/luonnonmetsat"}>{t("luonnonmetsät")}</Link></NavItem>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/hakkuutjalajit"}><Link to={"/hakkuutjalajit"}>{t("lajit")}</Link></NavItem>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/hakkuutjaelinymparistot"}><Link to={"/hakkuutjaelinymparistot"}><TwoRowNavItemContainer>
                    <Trans
                        i18nKey="menus:elinympäristöt"
                        components={twoRowNavItemComponents}
                    />
                </TwoRowNavItemContainer></Link></NavItem>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/hakkuidenhistoriaa"}>
                    <Link to={"/hakkuidenhistoriaa"}>
                        <TwoRowNavItemContainer>
                            <Trans
                                i18nKey="menus:hakkuidenHistoria"
                                components={twoRowNavItemComponents}
                            />
                        </TwoRowNavItemContainer>
                    </Link>
                </NavItem>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/lahimetsamme"}>
                    <Link to={"/lahimetsamme"}>
                        <TwoRowNavItemContainer>
                            <TwoRowNavItemContainer>
                                <Trans
                                    i18nKey="menus:lähimetsät"
                                    components={twoRowNavItemComponents}
                                />
                            </TwoRowNavItemContainer>
                            {/* <BetaText>BETA</BetaText> */}
                        </TwoRowNavItemContainer>
                    </Link>
                </NavItem>
                <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/vanhojametsia"}>
                    <Link to={"/vanhojametsia"}>
                        <TwoRowNavItemContainer>
                            <TwoRowNavItemContainer>
                                <Trans
                                    i18nKey="menus:metsoPotentiaali"
                                    components={twoRowNavItemComponents}
                                />
                            </TwoRowNavItemContainer>
                            {/* <BetaText>BETA</BetaText> */}
                        </TwoRowNavItemContainer>
                    </Link>
                </NavItem>
                {/* <NavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname === "/omatalueet"}><Link to={"/omatalueet"}>{t("omatAlueet")}</Link></NavItem> */}
                {/* <SignInSignOutButtonContainer>
                    <SignInSignOutButton />
                </SignInSignOutButtonContainer> */}
            </TopMenuNav>
            <TopMenuNav>
                <AlertsNavItem currentBackgroundColor={currentBackgroundColor} isCurrent={location.pathname.startsWith("/halytykset")}><Link to={"/halytykset"}>{t("sähköpostihälytykset")}</Link></AlertsNavItem>
                <div style={{ "display": "flex", "alignItems": "center" }}>
                    <LanguageSelector />
                </div>
            </TopMenuNav>
        </TopMenuContainer>
    )
}