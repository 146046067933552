import React, { createContext, ReactElement, useEffect, useRef, useState } from 'react'
import Point from "@arcgis/core/geometry/Point";
import Geometry from "@arcgis/core/geometry/Geometry";
import notificationsLegendData from './notificationsLegendData';
import MapView from '@arcgis/core/views/MapView';
import { useTranslation } from 'react-i18next';
import { IItemWithLanguageOptions } from '../components/TranslatedOption/TranslatedOption';

export interface INotificationsLegendDataItem extends IItemWithLanguageOptions {
    imageData: string,
    values: string[]
}

export interface ICodeSets {
    habitatCodes: ICodeSetEntry[],
    detailedHabitatCodes: ICodeSetEntry[],
    otherHabitatCodes: ICodeSetEntry[],
    specialFeatureAdditionalCodes: ICodeSetEntry[],
    cuttingRestrictions: ICodeSetEntry[]
}

export type CodeSetType = keyof ICodeSets;

export interface ICodeSetEntry {
    Koodi: number,
    Selite_FI: string,
    Selite_SE: string,
    Selite_EN: string
};

export class CodeSetEntry implements ICodeSetEntry, IItemWithLanguageOptions {
    Koodi: number;
    Selite_FI: string;
    Selite_SE: string;
    Selite_EN: string;

    constructor(Koodi: number, Selite_FI: string, Selite_SE: string, Selite_EN: string) {
        this.Koodi = Koodi;
        this.Selite_FI = Selite_FI;
        this.Selite_SE = Selite_SE;
        this.Selite_EN = Selite_EN;
    }

    public get nameFi(): string {
        return this.Selite_FI;
    }

    public get nameSv(): string {
        return this.Selite_SE;
    }

    public get nameEn(): string {
        return this.Selite_EN;
    }
}

export interface IMapContext {
    navigateToPoint: (point: Point | null | Geometry | IPointWithZoom) => void,
    pointToNavigateTo: Point | null | Geometry | IPointWithZoom,
    setMapAndViewReady: (view: MapView) => void,
    mapAndViewReady: boolean,
    legendData: INotificationsLegendDataItem[] | null,
    isSketching: boolean,
    codeSets: ICodeSets | null,
    getView: () => __esri.MapView | null,
    setCodeSets: (codeSets: ICodeSets) => void,
    setIsSketching: (isSketching: boolean) => void,
    getCodeText: (code: number, codeSetType: CodeSetType) => string,
    getNotificationTypeText: (notificationType: number) => string,
    gridOpacity: number,
    setGridOpacity: (value: number) => void
}

export const MapContext = createContext({} as IMapContext);

interface IProps {
    children: ReactElement
}

export interface IPointWithZoom {
    point: Point,
    zoom: number,
    type: "IPointWithZoom"
}

export default function MapContextContainer(props: IProps) {
    const loadStuffRef = useRef(false);
    const [pointToNavigateTo, setPointToNavigateTo] = useState<Point | null | Geometry | IPointWithZoom>(null);
    const [mapAndViewReady, setMapAndViewReady] = useState(false);
    const [legendData, setLegendData] = useState<INotificationsLegendDataItem[] | null>(null);
    const [codeSets, setCodeSets] = useState<ICodeSets | null>(null);
    const [isSketching, setIsSketchingState] = useState(false);
    const [gridOpacity, setGridOpacity] = useState(0.4);
    const viewRef = useRef<undefined | MapView>();
    const { i18n } = useTranslation();

    const navigateToPoint = (point: Point | null | Geometry | IPointWithZoom) => {
        setPointToNavigateTo(point);
    };

    const setMapAndViewReadyHandler = (view: MapView) => {
        setMapAndViewReady(true);

        viewRef.current = view;
    };

    const setIsSketching = (isSketching: boolean) => {
        setIsSketchingState(isSketching);
    };

    const getCodeText = (code: number, codeSetType: CodeSetType) => {
        if (codeSets === null) {
            return "";
        }

        const codeItem = codeSets[codeSetType].find(h => h.Koodi === code);

        if (codeItem) {
            if (i18n.language === "en") {
                if (codeItem.Selite_EN === "" || codeItem.Selite_EN === null || codeItem.Selite_EN === undefined) {
                    return "(Fin) " + codeItem.Selite_FI;
                }

                return codeItem.Selite_EN;
            }


            return codeItem.Selite_FI;
        }

        return "";
    }

    const getNotificationTypeText = (notificationType: number) => {
        const legendItem = legendData?.find((item) => {
            return item.values.includes("" + notificationType + "");
        });

        if (legendItem) {
            if (i18n.language === "en") {
                return legendItem.nameEn;
            }

            return legendItem.nameFi;
        }

        return "Tuntematon hakkuutyyppi";
    };

    useEffect(() => {
        if (loadStuffRef.current === false) {
            const legendResponse = notificationsLegendData;

            setLegendData(legendResponse?.layers[0]?.legend);

            loadStuffRef.current = true;
        }

        return () => {
            console.log("Destroy map context");

            viewRef.current = undefined;
        }
    }, []);

    const getView = () => {
        if (viewRef.current !== undefined) {
            return viewRef.current;
        }

        return null;
    };

    return (
        <MapContext.Provider value={{
            legendData,
            mapAndViewReady,
            navigateToPoint,
            getView: getView,
            getNotificationTypeText,
            pointToNavigateTo,
            gridOpacity,
            setGridOpacity,
            setMapAndViewReady: setMapAndViewReadyHandler,
            isSketching,
            getCodeText,
            codeSets,
            setCodeSets,
            setIsSketching
        }}>
            {props.children}
        </MapContext.Provider>
    );
}
