import React, { ReactElement, createContext, useContext, useEffect, useState } from 'react'
import errors, { IError } from './errors'
import loadingNotifications, { ILoadingNotification } from './loadingNotifications';
import { ICodeSets, MapContext } from './MapContextContainer';
import { useLocation } from 'react-router-dom';
import Point from '@arcgis/core/geometry/Point';
import { PREFERRED_WKID } from '../pages/utils';

export interface IPageContext {
    mapErrors: IError[],
    setMapError: (error: IError) => void,
    clearMapError: (error: IError) => void,
    thingsBeingLoaded: ILoadingNotification[],
    setLoadingSomething: (loadingNotification: ILoadingNotification) => void,
    resetLoadingSomething: (loadingNotification: ILoadingNotification) => void
}

interface IProps {
    children: ReactElement
}

export const PageContext = createContext({} as IPageContext);

export default function PageContextContainer(props: IProps) {
    const [mapErrors, setMapErrors] = useState<IError[]>([]);
    const [thingsBeingLoaded, setThingsBeingLoaded] = useState<ILoadingNotification[]>([]);
    const mapContext = useContext(MapContext);
    const loc = useLocation();

    const setMapError = (errorMsg: IError) => {
        const indexOfSimilarMessage = mapErrors.findIndex(e => e.translationKey === errorMsg.translationKey);

        if (indexOfSimilarMessage >= 0) {
            // No-op, we already have this message
        }
        else {
            setMapErrors((prevArr) => [
                ...prevArr,
                errorMsg
            ]);
        }
    };

    useEffect(() => {
        if (mapContext.codeSets === null) {
            const asyncCallToGetCodeSets = async () => {
                try {
                    setLoadingSomething(loadingNotifications.loadingCodeSets);

                    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/GetCodeSetsV2`);

                    const codeSets: ICodeSets = await response.json();

                    mapContext.setCodeSets(codeSets);

                    resetLoadingSomething(loadingNotifications.loadingCodeSets);
                } catch (error) {
                    setMapError(errors.codeSetsLoadingError);
                }
            }

            asyncCallToGetCodeSets();
        }
    }, []);

    useEffect(() => {
        const searchString = loc.search;

        if (mapContext.mapAndViewReady && searchString && searchString.startsWith("?point")) {
            const stripStart = searchString.replace("?", "");
            const parts = stripStart.split("&");

            if (parts.length === 2 &&
                parts[0].startsWith("point=") &&
                parts[1].startsWith("zoom=")) {

                const pointString = parts[0].split("=")[1];
                const zoom = parts[1].split("=")[1];

                if (pointString && zoom) {
                    const point = new Point({
                        latitude: parseFloat(pointString.split(",")[0]),
                        longitude: parseFloat(pointString.split(",")[1]),
                        spatialReference: { wkid: PREFERRED_WKID }
                    });

                    mapContext.navigateToPoint({ point, zoom: parseInt(zoom, 10), type: "IPointWithZoom" });
                }
            }
        }
    }, [mapContext.mapAndViewReady, loc.search]);

    const clearMapError = (errorMsg: IError) => {
        const allOthers = mapErrors.filter(e =>
            e.translationKey !== errorMsg.translationKey
        );

        setMapErrors(allOthers);
    };

    const setLoadingSomething = (loadingNotification: ILoadingNotification) => {
        setThingsBeingLoaded((existingArr) =>
            [
                ...existingArr,
                loadingNotification
            ]
        );
    };

    const resetLoadingSomething = (loadingNotification: ILoadingNotification) => {
        setThingsBeingLoaded((existingArr) => {
            return existingArr.filter(e => e.translationKey !== loadingNotification.translationKey);
        });
    };

    return (
        <PageContext.Provider value={{
            clearMapError,
            mapErrors,
            setMapError,
            setLoadingSomething,
            resetLoadingSomething,
            thingsBeingLoaded
        }}>
            {props.children}
        </PageContext.Provider>
    )
}